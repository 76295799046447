<template>
  <v-form v-model="formValid" ref="form">
    <DIDListImport
      :visible="showImport"
      @close="showImport = false"
      @import="importDIDs"
    />
    <div class="float-right">
      <ButtonWithTooltip
        text="Import list"
        icon="mdi-playlist-plus"
        tooltip="Import a list of numbers via copy/paste"
        @click="showImport = true"
        flat
      />
    </div>
    <v-row dense>
      <v-col cols="12">
        <p class="subtitle-1 mb-0 text--primary">{{ message }}</p>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <v-alert v-if="imported > 0" class="mt-4" type="info" outlined>
          {{ imported }} number<span v-if="imported > 1">s</span> added to the list. Please review details to ensure correct before proceeding.
        </v-alert>
        <v-simple-table dense>
          <thead>
          <tr>
            <th>
              <v-checkbox
                v-model="selectAll"
                @change="changeSelectAll"
                :indeterminate="indeterminateSelectAll"
              />
            </th>
            <th>
              <span v-if="!selectAll"> Phone Number </span>
            </th>
            <th v-if="showAccountNumber">
              <span v-if="!selectAll">{{accountNumberTitle}}</span>
            </th>
            <th v-if="porting">
              <span v-if="!selectAll">Port Type</span>
              <v-select
                v-if="selectAll"
                :items="portTypes"
                item-value="value"
                item-text="text"
                hide-details
                outlined
                dense
                @change="bulkUpdatePortType"
              />
            </th>
            <th v-if="showVendor">
              <span v-if="!selectAll">Vendor</span>
              <v-select
                v-if="selectAll"
                :items="vendors"
                item-value="value"
                item-text="text"
                hide-details
                outlined
                dense
                @change="bulkUpdateVendor"
                style="max-width: 240px"
                hint="Vendor"
              />
            </th>
            <th v-if="trunkData != null">
              <div v-if="!selectAll">Emergency Service Address</div>
              <div v-else style="width:180px">
                <v-row>
                  <v-col cols="6" class="pa-3 pr-0 pt-0 pl-6">
                    <ButtonWithTooltip
                      icon="mdi-format-list-bulleted"
                      @click="openIpndDialog(null,'list')"
                      color="primary"
                      tooltip="Select Emergency Service Address"
                      flat
                    />
                    <ButtonWithTooltip
                      icon="mdi-pencil"
                      @click="openIpndDialog(null,'create')"
                      color="primary"
                      tooltip="Create Emergency Service Address"
                      flat
                    />
                  </v-col>
                  <v-col cols="6" class="pa-3 pl-0 pt-0">
                    <v-text-field
                      @input="bulkUpdateIpndId"
                      placeholder="ipnd_id"
                      hide-details
                      outlined
                      dense
                    />
                  </v-col>
                </v-row>
              </div>
            </th>
            <th v-if="showCarrierStatus" class="text-center">
              Carrier Status
            </th>
            <th v-if="showDescription">
              <span v-if="!selectAll">Description</span>
              <v-text-field
                v-if="selectAll"
                @input="bulkUpdateDescription"
                hide-details
                outlined
                dense
              />
            </th>
            <th class="text-center">
              <span v-if="!selectAll">Delete</span>
              <ButtonWithTooltip
                v-if="selectAll"
                icon="mdi-delete"
                @click="bulkDelete"
                color="error"
                tooltip="Delete selected number(s) from the list"
                flat
              />
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-if="didInputList.length === 0">
            <td colspan="7" class="text-center">
              There are no numbers in the list.
            </td>
          </tr>
          <tr
            v-for="(did, index) in didInputList"
            v-bind:key="index"
            dense
          >
            <td class="text-center py-0">
              <v-checkbox
                v-model="did.selected"
                @change="updateSelected"
              />
            </td>
            <td class="text-center py-0">
              <v-text-field
                v-model="did.number"
                @input="validateEntry(index)"
                required
                outlined
                dense
                hide-details
                :rules="[
                                        (v) =>
                                            /^(?:(?:61|0)[2,3,4,7,8]\d{6}(?:\d{2}|\d0-9|00-99)|(?:61)?(?:1300|1800)\d{6}|(?:64)[1,3-9]\d{7})$/gm.test(
                                                v
                                            ) || 'Invalid number entered',
                                    ]"
                :error="did.validated && !did.valid"
                style="max-width: 320px; min-width: 260px"
              >
                <template v-slot:append>
                  <v-chip
                    label
                    small
                    v-if="
                                                did.quantity > 1 &&
                                                did.number &&
                                                did.valid &&
                                                (did.validated ||
                                                    !validateNumbers)
                                            "
                    class="mr-2 success"
                  >{{ did.quantity }} DIDs</v-chip
                  >
                  <icon-with-tooltip
                    v-if="
                                                did.number &&
                                                did.valid &&
                                                did.validated
                                            "
                    tooltip="The number/range appears to be valid"
                    icon="mdi-check"
                    color="success"
                  />
                  <icon-with-tooltip
                    v-if="
                                                did.number &&
                                                !did.valid &&
                                                !did.busy &&
                                                did.validated
                                            "

                    tooltip="Already exists in DID inventory!"
                    icon="mdi-alert"
                    color="error"
                  />
                  <v-progress-circular
                    v-if="did.busy && !did.validated"
                    :width="3"
                    :size="24"
                    color="primary"
                    indeterminate
                  ></v-progress-circular>
                </template>
              </v-text-field>
            </td>
            <td
              v-if="showAccountNumber"
              class="text-center py-0"
            >
              <v-text-field
                v-model="did.account_number"
                required
                hide-details
                outlined
                dense
                style="max-width: 160px; min-width: 160px"
              />
            </td>
            <td v-if="porting" class="text-center py-0">
              <v-select
                v-model="did.port_type"
                :items="portTypes"
                item-value="value"
                item-text="text"
                :required="porting"
                :rules="[(v) => !!v]"
                hide-details
                outlined
                dense
              />
            </td>

            <td v-if="showVendor" class="text-center py-0">
              <v-select
                v-model="did.i_vendor"
                @change="carrierStatus(index) && update()"
                :items="vendors"
                item-value="value"
                item-text="text"
                :required="showVendor"
                :rules="[(v) => !!v]"
                hide-details
                outlined
                dense
                style="max-width: 240px; min-width: 120px"
              />
            </td>
            <td class="text-center py-0" v-if="trunkData != null">
              <div v-if="did.i_vendor == null || !vendorIpndRequirement[did.i_vendor]">
                N/A
              </div>
              <div v-else style="width:180px">
                <v-row>
                  <v-col cols="12" class="pb-1">
                    <span v-if="did.ipndGenericData != null">{{did.ipndGenericData.address_string}}</span>
                    <span v-else-if="did.ipnd_id != null">ipnd_id: {{did.ipnd_id}}</span>
                    <span v-else style="color:red">Required</span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6" class="pa-3 pr-0 pt-0">
                    <ButtonWithTooltip
                      icon="mdi-format-list-bulleted"
                      @click="openIpndDialog(index,'list')"
                      :color="did.ipndGenericData == null && did.ipnd_id==null?'error':'primary'"
                      tooltip="Select Emergency Service Address"
                      flat
                    />
                    <ButtonWithTooltip
                      icon="mdi-pencil"
                      @click="openIpndDialog(index,'create')"
                      :color="did.ipndGenericData == null && did.ipnd_id==null?'error':'primary'"
                      tooltip="Create Emergency Service Address"
                      flat
                    />
                  </v-col>
                  <v-col cols="6" class="pa-3 pl-0 pt-0">
                    <v-text-field
                      v-model="did.ipnd_id"
                      @input="updateIpnd(index)"
                      placeholder="ipnd_id"
                      hide-details
                      outlined
                      dense
                    />
                  </v-col>
                </v-row>
              </div>
            </td>
            <td
              v-if="showCarrierStatus"
              class="text-center py-0"
            >
              <DIDCarrierStatus
                :status="did.carrier_status"
                v-if="
                                        did.i_vendor === 6 &&
                                        !did.busy &&
                                        did.valid
                                    "

              />
              <v-chip v-if="!did.valid || did.i_vendor !== 6">N/A</v-chip>
              <v-progress-circular
                v-if="
                                        did.busy && did.valid && did.validated
                                    "
                :width="3"
                :size="24"
                color="primary"
                indeterminate
              ></v-progress-circular>
            </td>


            <td v-if="showDescription" class="text-center py-0">
              <v-text-field
                v-model="did.description"
                :rules="[(v) => !!v]"
                hide-details
                outlined
                dense
              />
            </td>
            <td class="text-center py-0">
              <ButtonWithTooltip
                icon="mdi-delete"
                color="error"
                @click="removeDID(index)"
                tooltip="Delete this number from the list"
                flat
              />
            </td>
          </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-row dense class="mt-2">
      <v-col cols="12" class="text-center">
        <ButtonWithTooltip
          @click="addDID"
          icon="mdi-plus-circle"
          tooltip="Add another number"
          text="Add another number"
          flat
          block
        />
      </v-col>
    </v-row>

    <EditTrunkIPNDAddressDialog v-if="trunkData != null"
                                v-model="ipndDetailsDialog"
                                :porta-account-data="trunkData"
                                :current-ipnd-list="selectableIpndList"
                                :return-values="true"
                                @selected="selectIpnd"
    />
    <IPNDListSelectDialog v-if="selectableIpndList != null && selectableIpndList.length > 0"
                          :ipnd-list="selectableIpndList"
                          v-model="ipndListDialog"
                          @singleSelected="selectIpnd"
    />
    <SimpleDialog v-else
                  v-model="ipndListDialog"
                  title="No Emergency Service Addresses"
                  :okOnly="true"
    >
      <p>This Trunk/Account has no Emergency Service Addresses to select from. Please create a new Emergency Service Address instead.</p>
    </SimpleDialog>


  </v-form>
</template>

<script>
import DIDListImport from './DIDListImport';
import IconWithTooltip from '../IconWithTooltip';
import ButtonWithTooltip from '../ButtonWithTooltip';
import allStates from '../../../constants/states';
import DIDCarrierStatus from './DIDCarrierStatus';
import apiMixin from '../../../mixins/apiMixin';
import GlobalHelperMixin from "../../../mixins/GlobalHelperMixin";
import IPNDListSelectDialog from "../../dialogs/IPND/IPNDListSelectDialog.vue";
import EditTrunkIPNDAddressDialog from "../../dialogs/Trunk/EditTrunkIPNDAddressDialog.vue";
import SimpleDialog from "../../dialogs/templates/SimpleDialog.vue";
export default {

  name: 'DIDListInput',
  mixins: [apiMixin, GlobalHelperMixin],
  components: {
    SimpleDialog, EditTrunkIPNDAddressDialog, IPNDListSelectDialog,
    DIDCarrierStatus,
    ButtonWithTooltip,
    IconWithTooltip,
    DIDListImport,
  },
  data: () => ({
    showImport: false,
    indeterminateSelectAll: false,
    imported: false,
    selectAll: false,
    formValid: false,
    didInputList: [],
    portTypes: [
      { value: 1, text: 'Cat A' },
      { value: 2, text: 'Cat C' },
    ],
    states: allStates.allStates,

    // vendors: [
    //     { value: 'net-sip', text: 'NetSIP' },
    //     { value: 'vx-group', text: 'VX Group' },
    // ],
    //this is build on creation to cater for different environments
    vendors: null,
    vendorIpndRequirement: {},
    currentActiveDidIndex:null,
    ipndDetailsDialog:false,
    ipndListDialog:false,


  }),
  props: {
    title: {
      type: String,
      default: null,
    },
    porting: {
      type: Boolean,
      default: false,
    },
    saved: {
      type: Boolean,
      default: false,
    },
    accountNumberTitle: {
      type: String,
      default: 'Account Number',
    },
    message: {
      type: String,
      default: 'Please enter the list of numbers (DIDs) below',
    },
    showVendor: {
      type: Boolean,
      default: false,
    },
    validateNumbers: {
      type: Boolean,
      default: false,
    },
    showAccountNumber: {
      type: Boolean,
      default: false,
    },
    showCarrierStatus: {
      type: Boolean,
      default: false,
    },
    showDescription: {
      type: Boolean,
      default: false,
    },

    /*
    Trunk data needs to be passed for the IPND logic
     */
    trunkData: {
      type: Object,
      default: null,
    },

  },
  methods: {
    update() {
      this.$emit('update', this.didList());
    },
    importDIDs(data) {
      this.removeBlanks();
      let i = 0;
      data.forEach((value) => {
        if (value.length === 10) {
          value = `61${
            value.charAt(0) === '0' ? value.substring(1) : value
          }`;
        }
        if (!this.numberExists(value)) {
          let newLength = this.addDID(value);
          this.validateEntry(newLength - 1);
          i++;
        }
      });
      this.$refs.form.validate();
      this.imported = i;
      this.update();
    },
    numberExists(value) {
      return (
        this.didInputList.filter((x) => x.number === value).length > 0
      );
    },
    async validateDID(did) {
      await this.$nextTick();
      let response = await this.Api.send('post',
        'dids/' + did + '/validate',
        null, true
      );
      return !!response.success;
    },
    async getCarrierStatus(did) {
      await this.$nextTick();
      let response = await this.Api.send('post',
        'dids/' + did + '/carrierstatus',
        null, true
      );
      return response.data.status;
    },
    bulkUpdatePortType(value) {
      this.didInputList.forEach((item) => {
        if (item.selected) {
          item.port_type = value;
        }
      });
      this.$nextTick();
      this.update();
    },
    bulkUpdateVendor(value) {
      for (var i = 0; i < this.didInputList.length; i++) {
        if (
          this.didInputList[i].selected &&
          this.didInputList[i].i_vendor !== value
        ) {
          this.didInputList[i].i_vendor = value;
          this.carrierStatus(i);
        }
      }
      this.$nextTick();
      this.update();
    },
    bulkUpdateDescription(value) {
      this.didInputList.forEach((item) => {
        if (item.selected) {
          item.description = value;
        }
      });
      this.$nextTick();
    },

    bulkUpdateIpndId(value) {
      let self = this;
      this.didInputList.forEach(function (item, i) {
        if (item.selected) {
          //item.ipnd_id = value;
          self.$set(self.didInputList[i], 'ipnd_id', value);
          self.updateIpnd(i);
        }
      });
      this.$nextTick();
      this.update();
    },

    validateEntry(index) {
      this.didInputList[index].carrier_status = null;
      this.didInputList[index].validated = false;
      // change leading '0' to '61'
      if (this.didInputList[index].number.charAt(0) === '0') {
        this.didInputList[index].number = `61${this.didInputList[
          index
          ].number.substring(1)}`;
      }
      // update range boolean
      this.didInputList[index].quantity =
        this.didInputList[index].number.search('00-99') > 0
          ? 100
          : this.didInputList[index].number.search('0-9') > 0
            ? 10
            : 1;
      // check validity via regex
      this.didInputList[index].valid = this.isValidNumber(
        this.didInputList[index].number
      );

      // check validity online
      if (this.didInputList[index].valid && this.validateNumbers) {
        this.didInputList[index].busy = true;
        this.didInputList[index].validated = false;
        return new Promise((done) => {
          const handleResult = (index) => (result) => {
            this.didInputList[index].busy = false;
            this.didInputList[index].valid = result;
            this.didInputList[index].validated = true;
            if (this.didInputList[index].i_vendor === 6) {
              this.carrierStatus(index);
            } else {
              this.update();
            }
          };
          this.validateDID(this.didInputList[index].number)
            .then(handleResult(index))
            .catch(handleResult(index));
          done();
        });
      }
    },
    carrierStatus(index) {
      this.didInputList[index].carrier_status = null;
      if (
        this.didInputList[index].i_vendor === 6 &&
        this.didInputList[index].valid &&
        this.didInputList[index].validated
      ) {
        this.didInputList[index].busy = true;
        return new Promise((done) => {
          const handleResult = (index) => (result) => {
            this.didInputList[index].busy = false;
            this.didInputList[index].carrier_status = result;
          };
          this.getCarrierStatus(this.didInputList[index].number)
            .then(handleResult(index))
            .catch(handleResult(index));
          done();
        });
      }
      this.update();
    },
    isValidNumber(value) {
      return /^(?:(?:61|0)[2,3,4,7,8]\d{6}(?:\d{2}|\d0-9|00-99)|(?:61)?(?:1300|1800)\d{6}|(?:64)[1,3-9]\d{7})$/gm.test(
        value
      );
    },
    bulkDelete() {
      this.didInputList = this.didInputList.filter((x) => !x.selected);
      if (this.didInputList.length === 0) this.addDID();
      this.update();
      this.$nextTick();
    },
    removeBlanks() {
      this.didInputList = this.didInputList.filter(
        (x) =>
          x.number !== '' &&
          x.number !== null &&
          x.number !== undefined
      );
      this.$nextTick();
    },
    addDID(number) {
      let index = this.didInputList.push({
        number: number ? number : '',
        i_vendor: null,
        port_type: null,
        selected: false,
        valid: false,
        account_number: null,
        description: null,
        quantity: 1,
        validated: false,
        busy: false,
        carrier_status: null,
      });
      if (number) this.updateSelected();
      return index;
    },
    removeDID(index) {
      this.didInputList.splice(index, 1);
      if (this.didInputList.length === 0) this.addDID('');
      this.update();
      this.updateSelected();
    },
    changeSelectAll() {
      this.didInputList.forEach((item) => {
        item.selected = this.selectAll;
      });
    },
    updateSelected() {
      let selectedDIDs = this.didInputList.filter(
        (x) => x.selected
      ).length;
      this.selectAll = selectedDIDs > 0;
      this.indeterminateSelectAll =
        selectedDIDs > 0 && selectedDIDs < this.didInputList.length;
    },

    openIpndDialog: function(didIndex, type){
      this.currentActiveDidIndex = didIndex;
      if(type == 'list'){
        this.ipndListDialog = true;
      }else if(type == 'create'){
        this.ipndDetailsDialog = true;
      }else{
        console.error('Unrecognised type ('+type+').');
      }
    },
    selectIpnd: function(ipndValues){
      if(this.selectAll || this.indeterminateSelectAll){
        let self = this;
        this.didInputList.forEach(function (item, i) {
          if(item.selected){
            self.$set(self.didInputList[i], 'ipndGenericData', ipndValues);
            self.$set(self.didInputList[i], 'ipnd_id', null);
          }
        });

        // let selectedDIDs = this.didInputList.filter(
        //   (x) => x.selected
        // );
        // selectedDIDs.forEach((did)=>{
        //   this.$set(did, 'ipndGenericData', ipndValues); //make this property in the object reactive.
        //   //did.ipndGenericData = ipndValues;
        //   this.$set(did, 'ipnd_id', null);
        // });
      }else{
        if(this.didInputList[this.currentActiveDidIndex] == null){
          if(this.isPortaStaging()){
            alert('Failed to find DID are index ('+this.currentActiveDidIndex+').');
          }
        }else{
          this.$set(this.didInputList[this.currentActiveDidIndex], 'ipndGenericData', ipndValues);
          this.$set(this.didInputList[this.currentActiveDidIndex], 'ipnd_id', null);
          //this.didInputList[this.currentActiveDidIndex].ipndGenericData = ipndValues;
        }
      }

      /*
        We now run the update process.
        This will pull the didList and reprocess validation.
        As selecting a IPND can validate the form and we need to trigger it in this way.
       */
      this.$nextTick();
      this.update( );
    },
    validateIPNDRequirement: function(){
      let pass = false;
      if(this.trunkData == null){
        return true;
      }
      this.didInputList.forEach((did)=>{
        if(!did.i_vendor || did.i_vendor == null){
          return;
        }
        if(!this.vendorIpndRequirement[did.i_vendor]){
          return;
        }
        if(did.ipndGenericData != null || did.ipnd_id != null){
          pass = true;
        }
      });
      return pass;
    },
    updateIpnd: function(index){
      this.didInputList[index].ipndGenericData = null;
      if(this.didInputList[index].ipnd_id == ''){
        this.didInputList[index].ipnd_id = null;
      }
      this.$nextTick();
      this.update( );
    },

    didList: function () {
      if (!this.formValid || !this.validateIPNDRequirement( )){
        return [];
      }
      let results = [];
      this.didInputList.forEach((value) => {
        if (value.number && value.valid) {
          if (value.number.search('0-9') > 0) {
            if (value.number.search('00-99') > 0) {
              for (i = 0; i < 100; i++) {
                results.push({
                  number: String(
                    value.number.substr(
                      0,
                      value.number.length - 5
                    ) + String(i).padStart(2, '0')
                  ),
                  i_vendor: value.i_vendor,
                  ipnd_id: value.ipnd_id,
                });
              }
            } else {
              for (i = 0; i < 10; i++) {
                results.push({
                  number: String(
                    value.number.substr(
                      0,
                      value.number.length - 3
                    ) + String(i)
                  ),
                  i_vendor: value.i_vendor,
                  ipnd_id: value.ipnd_id,
                });
              }
            }
          } else {
            var i;
            switch ((value.number.match(/#/g) || []).length) {
              case 1:
                for (i = 0; i < 10; i++) {
                  results.push({
                    number:
                      value.number.substr(
                        0,
                        value.number.length - 1
                      ) + String(i),
                    i_vendor: value.i_vendor,
                    ipnd_id: value.ipnd_id,
                  });
                }
                break;
              case 2:
                for (i = 0; i < 100; i++) {
                  results.push({
                    number:
                      value.number.substr(
                        0,
                        value.number.length - 2
                      ) + String(i).padStart(2, '0'),
                    i_vendor: value.i_vendor,
                    ipnd_id: value.ipnd_id,
                  });
                }
                break;
              default:
                results.push(value);
                break;
            }
          }
        }
      });
      return results;
    },

    reset:function( ){
      this.didInputList = [];
      this.addDID();

      let vendorDetails = this.getVenDetails( );
      this.vendors = [];
      let env = this.isPortaStaging ? 's':'p';

      this.vendors.push({value:vendorDetails[0]['vn'][env],text:'VX Group'});
      this.vendors.push({value:vendorDetails[1]['vn'][env],text:'NetSIP'});

      this.vendorIpndRequirement = this.getVnMappedToIpndRequirement( );
    }

  },
  computed: {
    isValid: function () {
      return this.$refs.form.validate();
    },
    selectableIpndList( ){
      let ret = [];
      if(this.trunkData && this.trunkData.ipnd_list){
        ret = this.trunkData.ipnd_list;
      }

      //run over all dids to unique ipndGenericValues
      this.didInputList.forEach((didIpnd)=>{
        let foundMatch = false;
        ret.forEach((trunkIpnd)=>{
          if(didIpnd.ipndGenericData != null && didIpnd.ipndGenericData.hash == trunkIpnd.hash){
            foundMatch = true;
            return;
          }
        })
        if(!foundMatch && didIpnd.ipndGenericData != null){
          ret.push(didIpnd.ipndGenericData);
        }
      });

      return ret;
    },

  },
  watch: {
    formValid: function () {
      this.update();
    },
  },
  created() {
    this.reset( );
  },
};
</script>
